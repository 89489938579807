@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);
.header.ant-layout-header {
  display: flex;
  justify-content: space-between;
  background-color: #212121; }
  .header.ant-layout-header .logo {
    color: #ffffff; }
  .header.ant-layout-header .navigation-buttons button {
    color: #ffffff;
    text-decoration: none; }

.home-wrapper .content .personal-info .active {
  opacity: 1;
  -webkit-transition: opacity linear 2s;
  transition: opacity linear 2s; }

.home-wrapper .content .personal-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; }
  .home-wrapper .content .personal-info > div {
    opacity: 0.01;
    -webkit-transition: opacity linear 2s;
    transition: opacity linear 2s; }
  .home-wrapper .content .personal-info .name {
    color: #ffffff;
    font-size: 3em;
    font-family: 'Montserrat';
    font-weight: 700;
    text-transform: uppercase; }
  .home-wrapper .content .personal-info .job-title {
    color: #bbbbbb;
    font-size: 2em; }
  .home-wrapper .content .personal-info .description {
    font-size: 1.5em;
    color: #ffffff; }
    .home-wrapper .content .personal-info .description .country {
      color: #20639b; }
    .home-wrapper .content .personal-info .description .company {
      color: #f23024; }
  .home-wrapper .content .personal-info .welcome {
    font-size: 1.5em;
    color: #ed553b; }
  .home-wrapper .content .personal-info .social-media-icons-container .social-media-icon {
    color: #ffffff;
    font-size: 2em;
    margin-right: 15px; }

.home-wrapper .content .words-stack {
  color: #cccccc;
  opacity: 0.1;
  font-size: 2em;
  text-transform: uppercase;
  overflow: hidden;
  height: 100%;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 700; }

.home-wrapper .content .main-introduction {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
  width: 100%;
  flex-direction: column; }
  .home-wrapper .content .main-introduction .main-background-info {
    width: 500px; }
  .home-wrapper .content .main-introduction .developer-image {
    height: 50%; }

.home-wrapper .header-row {
  height: 30rem;
  padding: 0 5em 0;
  background: #212121; }

.home-wrapper .bottom-container {
  height: 20rem;
  display: flex;
  justify-content: space-around; }
  .home-wrapper .bottom-container .skills-container {
    height: 100%; }
  .home-wrapper .bottom-container > div {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px; }
    .home-wrapper .bottom-container > div div {
      width: 200px; }

.blog-list-container {
  padding: 1rem;
  border-radius: 6px;
  background-color: #FAFAFA;
  cursor: pointer; }
  .blog-list-container .title {
    font-weight: bold; }
  .blog-list-container .description {
    color: #555555;
    font-size: 1em;
    line-height: 1em;
    max-height: 2em;
    word-wrap: normal;
    text-overflow: ellipsis; }
  .blog-list-container:hover {
    box-shadow: 1px 1px 3px #00000044; }

.blog-wrapper .content {
  display: flex;
  justify-content: center;
  height: 100vh; }
  .blog-wrapper .content .blog-list {
    width: 50vw; }

.blog-detail-page .content {
  padding: 1em;
  width: 50vw;
  margin: auto; }

.find-petrol-wrapper .content {
  margin-left: auto;
  margin-right: auto;
  min-width: 30em;
  width: 50%; }

.flex-row {
  display: flex;
  flex-direction: row; }

.flex-column {
  display: flex;
  flex-direction: column; }

.block {
  flex: 1 1; }

body {
  background-color: #f0f2f5; }

