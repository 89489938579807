.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.block {
  flex: 1;
}

body {
  background-color: #f0f2f5;
}
